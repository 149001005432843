import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Role } from '@kolytics/types/common';

@Injectable({
  providedIn: 'root',
})
export class ClaimService {
  private readonly RoleClaim =
    'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';

  constructor(private jwtService: JwtHelperService) {}

  getDecodedToken() {
    const token = this.jwtService.tokenGetter()?.toString();
    const decodedToken = this.jwtService.decodeToken(token);
    return decodedToken;
  }

  hasAdminRole(decodedToken: any) {
    const role = decodedToken[this.RoleClaim];
    const isArray =
      Array.isArray(role) &&
      ((role as string[]).includes(Role.Admin) ||
        (role as string[]).includes(Role.SuperAdmin));
    const isString = role === Role.Admin || role === Role.SuperAdmin;
    return isArray || isString;
  }

  hasRole(roleCheck: Role): boolean {
    const decodedToken = this.getDecodedToken();
    const role = decodedToken[this.RoleClaim];
    if (!role) return false;
    if (Array.isArray(role)) {
      return (role as string[]).includes(roleCheck);
    }
    return role == roleCheck;
  }

  isClaimValid(claimTypeValue: string) {
    const decodedToken = this.getDecodedToken();
    if (!decodedToken) return false;

    if (this.hasAdminRole(decodedToken)) {
      return true;
    }

    if (!claimTypeValue) {
      return false;
    }
    const values = claimTypeValue.split(':');
    if (values.length <= 1) {
      return false;
    }

    const claim = decodedToken[values[0]];
    if (!claim) {
      return false;
    }
    const isArray =
      Array.isArray(claim) && (claim as string[]).includes(values[1]);
    const isString = claim === values[1];

    if (!isArray && !isString) {
      return false;
    }
    return true;
  }

  hasClaim(claimTypeValue: string) {
    const isValid = this.isClaimValid(claimTypeValue);
    return isValid;
  }
}
