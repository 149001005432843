<div class="navigation--wrapper" [class.scrolling]="scrolling">
  <div class="navigation--container">
    <div class="navigation-container-left">
      <a routerLink="/reits/all" class="navigation-logo"></a>
      @if (user && user.trialPeriod) {
        <div class="trial-container">
          <span class="trial-text"
            >TRIAL ENDS: {{ user.trialPeriod | date: 'd-MMM-yy' }}</span
            >
          </div>
        }
        <!-- <div class="navigation-search">
        <klt-dropdown-wrapper
          (onClose)="this.isVisible = false"
          >
          <klt-input-expand
            class="search-input"
            (valueChanged)="search($event)"
            [theme]="'dark'"
            [placeholder]="'Search REITs & sectors…'"
            [expanded]="true"
            (valueCleared)="search('')"
            (click)="handleClickButton()"
            >
          </klt-input-expand>
          <klt-dropdown-group
            [visible]="this.isVisible && (searchValue ||  '').length > 2"
            [keyword]="searchValue"
            [data]="searchItems"
            (onChoose)="handleSelect($event)"
            >
          </klt-dropdown-group>
          <klt-dropdown-group
            [visible]="this.isVisible && !searchValue && recentSearches.data[0].items.length > 0"
            [data]="recentSearches"
            (onChoose)="handleSelect($event)"
            >
          </klt-dropdown-group>
        </klt-dropdown-wrapper>
      </div> -->
    </div>
    @if (isPreview) {
      <div class="navigation-container-right">
        <span class="preview-text">Preview mode</span>
      </div>
    }
    @if (!isPreview) {
      <div class="navigation-container-right">
        @for (n of nav; track n) {
          <ng-container *hasClaim="n.claim">
            @if (n.feature) {
              <div
                class="navigation-link"
                kltFeatured
                [feature]="n.feature"
                [routerLink]="n.route"
                [routerLinkActive]="['active']"
                >
                <div class="navigation-link-spacer"></div>
                <klt-icon
                  [size]="'medium'"
                  [icon]="n.icon"
                  [color]="n.route === currentRoute ? 'cta' : 'light'"
                  >
                </klt-icon>
                <div class="navigation-link-label">{{ n.token }}</div>
                <div class="navigation-link-underline"></div>
              </div>
            } @else {
              <div
                class="navigation-link"
                [routerLink]="n.route"
                [routerLinkActive]="['active']"
                >
                <div class="navigation-link-spacer"></div>
                <klt-icon
                  [size]="'medium'"
                  [icon]="n.icon"
                  [color]="n.route === currentRoute ? 'cta' : 'light'"
                  >
                </klt-icon>
                <div class="navigation-link-label">{{ n.token }}</div>
                <div class="navigation-link-underline"></div>
              </div>
            }
          </ng-container>
        }
        <div
          class="navigation-link"
          (click)="toggleProfile()"
          [ngClass]="{ active: currentRoute == '/account' }"
          >
          <div class="navigation-link-spacer"></div>
          <klt-icon
            [size]="'medium'"
            [icon]="'profile'"
            [color]="currentRoute == '/account' ? 'cta' : 'light'"
            >
          </klt-icon>
          <div class="navigation-link-label">Profile</div>
          <div class="navigation-link-underline"></div>
        </div>
        @if (isProfileOpen) {
          <div
            class="navigation-profile"
            @navigationProfileAnimation
            >
            <div class="navigation-profile-user">
              <h3>{{ user?.firstname }}</h3>
              <div class="email">{{ user?.email }}</div>
            </div>
            @for (item of profileNav; track item) {
              <div class="navigation-profile-item">
                <div
                  class="navigation-profile-content"
                  (click)="navigate(item.route)"
                  [ngClass]="{ active: item.route === currentRoute }"
                  >
                  <klt-icon
                    [size]="'small'"
                    [icon]="item.icon"
                    [color]="item.route === currentRoute ? 'cta' : 'dark'"
                    >
                  </klt-icon>
                  <span class="navigation-profile-content-text">{{
                    item.token
                  }}</span>
                </div>
              </div>
            }
            <div class="line"></div>
            <div class="navigation-profile-item" (click)="logout()">
              <div class="navigation-profile-content logout">
                <klt-icon
                  [size]="'small'"
                  [icon]="'log-out'"
                  [color]="'dark'"
                ></klt-icon>
                <span class="navigation-profile-content-text">Logout</span>
              </div>
            </div>
          </div>
        }
        @if (isProfileOpen) {
          <div
            @navigationOverlayAnimation
            class="navigation-profile-overlay"
            (click)="toggleProfile()"
          ></div>
        }
      </div>
    }
  </div>
  <div class="navigation--line"></div>
</div>
